@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    border-color: transparent;
}

#bar-wrapper {
    position: relative !important;
    top: 0px !important;
    left: 0px !important;
    max-height: 140px !important;
    min-height: 140px !important;
    width: 100% !important;
    height: 100% !important;
    margin: 0px !important;
    /*padding: 50px !important;*/
}

.accordion {
    position: relative !important;
    background-color: #0485a1 !important;
}

#VOE-Header {
    height: 140px !important;
    width: 100% !important;
    position: relative !important;
    top: 0px;
    left: 0px;
}

.voe-header-wrapper {
    /* the previous .h-36(9rem) class created unused space */
    height: 140px !important;
}

.MuiContainer-root {
    position: relative !important;
    top: 10px;
    margin: 0px;
}
.MuiAccordionSummary-content {
    margin: 0px !important;
    padding: 0px !important;
}

.MuiBox-root-5 {
    width: 241px !important;
    width: 100%;
}

.MuiBox-root-5 .MuiGrid-item {
    margin: 5px !important;
}

.MuiButtonBase-root {
    background-color: #d8d6d4;
}

.ForgeViewer {
}

button {
    outline: none;
}

button.grid {
    background: #0486a1;
    outline: none;
}

button.grid:hover {
    background: #156160;
}
button.grid:active,
button.grid:focus {
    background: #74cee1;
    outline: none;
}

button.grid:disabled {
    background: #d8d6d4;
}

// Removal of break points temporarily
@media screen and (min-width: 1280px) {
    .container {
        max-width: 100% !important;
    }
}

@media screen and (min-width: 1020px) {
    .container {
        max-width: 100% !important;
    }
}

@media screen and (min-width: 768px) {
    .container {
        max-width: 100% !important;
    }
}

@media screen and (min-width: 640px) {
    .container {
        max-width: 100% !important;
    }
}

/* App Drawer layout **************************/
.root: {
    display: "flex";
}
.appbar: {
}
.appbarshift: {
    /* width: `calc(100% - ${drawerWidth}px)`; */
    marginleft: drawerWidth;
}
.menubutton: {
    marginright: 10px;
}
.hide: {
    display: "none";
}
.drawer: {
    width: drawerWidth;
    flexshrink: 0;
}
.drawerpaper: {
    width: drawerWidth;
}
.drawerheader: {
    display: "flex";
    alignitems: "center";
    padding: 5px 0px;
    justifycontent: "flex-end";
}
.content: {
    flexgrow: 1;
    padding: 3px;
    marginleft: -240px;
}
.contentshift: {
    marginleft: 0;
}
